import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import withDictionary from '../../../hocs/withDictionary';
import { Button as ButtonButton, AnchorButton, Body } from './Button.style';
import Icon from '../Icon';
import LoadingSpinner from '../Loading/LoadingSpinner';

const defaultProps = {
  align: 'center',
  ariaLabel: '',
  backgroundColor: '',
  block: false,
  buttonStyle: {},
  children: null,
  className: '',
  color: '',
  disabled: false,
  icon: null,
  isLoading: false,
  href: null,
  marginBottom: null,
  marginLeft: null,
  marginRight: null,
  marginTop: null,
  maxWidth: null,
  minWidth: null,
  noPadding: false,
  onBlur: undefined,
  onClick: () => { },
  onFocus: undefined,
  onMouseDown: undefined,
  onMouseUp: undefined,
  onMouseOut: undefined,
  onMouseOver: undefined,
  outerRef: null,
  size: 'medium',
  target: null,
  theme: {},
  type: 'submit',
  'data-testid': null,
  textTransform: undefined,
  textDecoration: undefined,
  direction: undefined,
  id: undefined,
  iconSize: undefined,
};

const propTypes = {
  id: PropTypes.string,
  align: PropTypes.string,
  ariaLabel: PropTypes.string,
  backgroundColor: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  href: PropTypes.string,
  marginBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  marginLeft: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  marginRight: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  marginTop: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  maxWidth: PropTypes.string,
  minWidth: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  size: PropTypes.string,
  buttonStyle: PropTypes.shape({}),
  target: PropTypes.string,
  theme: PropTypes.shape({}),
  type: PropTypes.string,
  noPadding: PropTypes.bool,
  'data-testid': PropTypes.string,
  outerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  textTransform: PropTypes.string,
  textDecoration: PropTypes.string,
  direction: PropTypes.string,
  iconSize: PropTypes.string,
};

function Button({
  children,
  icon,
  ...props
}) {
  const buttonProps = {
    align: props.align,
    direction: props.direction,
    ariaLabel: props.ariaLabel,
    block: props.block,
    backgroundColor: props.backgroundColor,
    color: props.color,
    className: props.className,
    disabled: props.disabled,
    href: props.href,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    marginTop: props.marginTop,
    maxWidth: props.maxWidth,
    noPadding: props.noPadding,
    onBlur: props.onBlur,
    onFocus: props.onFocus,
    onMouseDown: props.onMouseDown,
    onMouseUp: props.onMouseUp,
    onMouseOver: props.onMouseOver,
    onMouseOut: props.onMouseOut,
    onClick: props.onClick,
    size: props.size,
    style: props.buttonStyle,
    type: props.type,
    ref: props.outerRef,
    target: props.target,
    id: props.id,
  };

  const bodyProps = {
    align: props.align,
    disabled: props.disabled,
    isLoading: props.isLoading,
    minWidth: props.minWidth,
    noPadding: props.noPadding,
    size: props.size,
    textTransform: props.textTransform,
    textDecoration: props.textDecoration,
    direction: props.direction,
  };

  let ButtonType = ButtonButton;
  if (buttonProps.href) {
    ButtonType = AnchorButton;
    delete buttonProps.type;
  }

  return (
    <ButtonType
      {...buttonProps}
      data-testid={props['data-testid']}
      aria-label={props.ariaLabel}
    >
      <>
        <Body {...bodyProps}>
          {icon && (
            <Icon icon={icon} color={props.color} size={props.iconSize} />
          )}
          {children}
        </Body>
        {props.isLoading &&
          <LoadingSpinner size="1.5rem" style={{ width: 0, marginLeft: '-50%' }} />
        }
      </>
    </ButtonType>
  );
}

Button.defaultProps = defaultProps;
Button.propTypes = propTypes;

export default withTheme(withDictionary(Button, { name: 'Button' }));
