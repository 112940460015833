export const isHTMLElement = (value) => {
  try {
    if (!window || !document) {
      return false;
    }
    return value instanceof Element || value instanceof Document;
  } catch {
    return false;
  }
};

export const focusElementAtStart = (element) => {
  element.focus();
  element.scrollIntoView({ block: 'center' });
  if (element?.setSelectionRange) {
    element.setSelectionRange(0, 0);
  }
};

export default isHTMLElement;
