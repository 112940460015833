const FetchPolicy = {
  // Apollo Client first executes the query against the cache. If all requested data
  // is present in the cache, that data is returned. Otherwise, Apollo Client executes
  // the query against your GraphQL server and returns that data after caching it.
  // Prioritizes minimizing the number of network requests sent by your application.
  // This is the default fetch policy.
  CACHE_FIRST: 'cache-first',
  // Apollo Client executes the query only against the cache. It never queries your
  // server in this case.
  // A cache-only query throws an error if the cache does not contain data for all requested fields.
  CACHE_ONLY: 'cache-only',
  // Apollo Client executes the full query against both the cache and your GraphQL server.
  // The query automatically updates if the result of the server-side query modifies cached fields.
  // Provides a fast response while also helping to keep cached data consistent with server dat
  CACHE_AND_NETWORK: 'cache-and-network',
  // Apollo Client executes the full query against your GraphQL server, without first
  // checking the cache. The query's result is stored in the cache.
  // Prioritizes consistency with server data, but can't provide a near-instantaneous
  // response when cached data is available.
  NETWORK_ONLY: 'network-only',
  // Similar to network-only, except the query's result is not stored in the cache.
  NO_CACHE: 'no-cache',
  STANDBY: 'standby',
};

export const FETCH_POLICY_KEYS = {
  SHARED_CONFIG: 'sharedConfig',
  SITES_CONFIG: 'sitesConfig',
  CROSSINGS_CONFIG: 'crossingsConfig',
};

export const fetchPolicyOptions = {
  defaultPolicy: FetchPolicy.CACHE_FIRST,
  expiry: 1000 * 60 * 5, // 5 minutes
  expiryPolicy: FetchPolicy.NETWORK_ONLY,
};

const getNextRefetchTime = (publicRuntimeConfig) => {
  // configScheduledFetch time pulled in to force a refetch
  const configScheduledFetch = publicRuntimeConfig?.ENVIRONMENT?.CONFIG_SCHEDULED_FETCH;
  const dateTime = new Date();
  const [hours, minutes, seconds] = configScheduledFetch?.split(':').map(n => parseInt(n, 10)) || [];
  dateTime.setHours(Number.isNaN(hours) ? 0 : hours);
  dateTime.setMinutes(Number.isNaN(minutes) ? 0 : minutes);
  dateTime.setSeconds(Number.isNaN(seconds) ? 0 : seconds);
  const nowTime = new Date().getTime();
  if (dateTime.getTime() <= nowTime) dateTime.setDate(dateTime.getDate() + 1);

  return dateTime;
};

/**
 * set up timeout to execute refetch at the configScheduledFetch time;
 */
export const setUpRefetchOnDateTime = (initialTimeoutId, onRefetch, publicRuntimeConfig) => {
  // In order to cope with a long running timer getting out of sync we reevaluate every 30
  // seconds
  const interval = 30 * 1000; // 30 seconds
  const dateTime = getNextRefetchTime(publicRuntimeConfig);
  const nowTime = new Date().getTime();
  const timeTo = dateTime.getTime() - nowTime;

  const setNewTimeout = (timeoutId, fn, delay) => {
    clearTimeout(timeoutId);
    return setTimeout(fn, delay);
  };

  let timeoutId = initialTimeoutId;

  // if we are under the sync interval then actually now set this to execute the refetch
  if (timeTo <= interval) {
    timeoutId = setNewTimeout(timeoutId, () => {
      onRefetch();
      setUpRefetchOnDateTime(timeoutId, onRefetch, publicRuntimeConfig);
    }, timeTo);
  } else {
    // repeat the interval to keep the timeout in sync.
    timeoutId = setNewTimeout(
      timeoutId, setUpRefetchOnDateTime.bind(
        null, timeoutId, onRefetch, publicRuntimeConfig,
      ), interval,
    );
  }
};

export default FetchPolicy;
