import React from 'react';
import PropTypes from 'prop-types';

import { isHTMLElement } from './lib/dom';

export const addressType = {
  addressLines: PropTypes.arrayOf(PropTypes.string),
  country: PropTypes.string,
  county: PropTypes.string,
  postCode: PropTypes.string,
};

export const userType = {
  address: PropTypes.shape(addressType),
  canProceedToBooking: PropTypes.bool,
  dateOfBirth: PropTypes.string,
  email: PropTypes.string,
  errorCode: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  membershipExpired: PropTypes.bool,
  membershipNumber: PropTypes.string,
  membershipStatus: PropTypes.number,
  name: PropTypes.string,
  personId: PropTypes.string,
  telephones: PropTypes.arrayOf(PropTypes.shape({
    countryPrefix: PropTypes.string,
    number: PropTypes.string,
  })),
  title: PropTypes.string,
  type: PropTypes.string,
  warnings: PropTypes.arrayOf(PropTypes.string),
};

export const networkErrorType = {
  result: PropTypes.string,
  statusCode: PropTypes.number,
  message: PropTypes.string,
  stack: PropTypes.string,
};

export const apolloErrorType = {
  graphQLErrors: PropTypes.arrayOf(PropTypes.string),
  message: PropTypes.string,
  networkError: PropTypes.shape(networkErrorType),
  responseText: PropTypes.string,
};

export const apolloDataType = {
  error: PropTypes.shape(apolloErrorType),
  fetchMore: PropTypes.func,
  loading: PropTypes.bool,
  networkStatus: PropTypes.number,
  refetch: PropTypes.func,
  startPolling: PropTypes.func,
  stopPolling: PropTypes.func,
  subscribeToMore: PropTypes.func,
  updateQuery: PropTypes.func,
  variables: PropTypes.shape({}),
};

export const partyMemberType = {
  age: PropTypes.number,
  comment: PropTypes.string,
  dateOfBirth: PropTypes.string,
  gender: PropTypes.string,
  isLead: PropTypes.bool,
  personId: PropTypes.number,
  // specialRequests: PropTypes.arrayOf(PropTypes.shape({})),
  telephones: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  type: PropTypes.number,
};

export const outfitType = {
  id: PropTypes.number,
  towType: PropTypes.number,
  vehicleLength: PropTypes.number,
  vehicleType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vehicleHeight: PropTypes.number,
  vehicleMake: PropTypes.string,
  vehicleModel: PropTypes.string,
  vehicleReg: PropTypes.string,
};

export const bookingType = {
  balanceDueDate: PropTypes.string,
  bcode: PropTypes.string,
  bookedDate: PropTypes.string,
  departureDate: PropTypes.string,
  isOverseasBooking: PropTypes.bool,
  items: PropTypes.number,
  outfit: PropTypes.string,
  outstandingBalance: PropTypes.number,
  partyDetails: PropTypes.string,
  returnDate: PropTypes.string,
  totalValue: PropTypes.number,
  emailAddress: PropTypes.string,
  phoneNumber: PropTypes.shape({
    countryPrefix: PropTypes.string,
    number: PropTypes.string,
  }),
};

export const clientType = {
  addResolvers: PropTypes.func,
  cache: PropTypes.shape({}),
  clearStore: PropTypes.func,
  clearStoreCallbacks: PropTypes.array,
  defaultOptions: PropTypes.shape({}),
  devToolsHookCb: PropTypes.func,
  disableNetworkFetches: PropTypes.bool,
  extract: PropTypes.func,
  getResolvers: PropTypes.func,
  initQueryManager: PropTypes.func,
  link: PropTypes.shape({}),
  localState: PropTypes.shape({}),
  mutate: PropTypes.func,
  onClearStore: PropTypes.func,
  onResetStore: PropTypes.func,
  query: PropTypes.func,
  queryDeduplication: PropTypes.bool,
  queryManager: PropTypes.shape({}),
  reFetchObservableQueries: PropTypes.func,
  readFragment: PropTypes.func,
  readQuery: PropTypes.func,
  resetStore: PropTypes.func,
  resetStoreCallbacks: PropTypes.array,
  restore: PropTypes.func,
  setLocalStateFragmentMatcher: PropTypes.func,
  setResolvers: PropTypes.func,
  stop: PropTypes.func,
  store: PropTypes.shape({}),
  subscribe: PropTypes.func,
  typeDefs: PropTypes.string,
  version: PropTypes.string,
  watchQuery: PropTypes.func,
  writeData: PropTypes.func,
  writeFragment: PropTypes.func,
  writeQuery: PropTypes.func,
};

export const checkoutExtraType = {
  id: PropTypes.number,
  title: PropTypes.string,
  contentTitle: PropTypes.string,
  content: PropTypes.string,
  price: PropTypes.number,
  max: PropTypes.number,
  imageUrl: PropTypes.string,
};

export const itxPackageRulesType = {
  qtyOvernightStaysRequired: PropTypes.number,
  qtyOverseasSiteNightVouchersRequired: PropTypes.number,
};

export const siteNightVoucherInfoType = {
  desktopImage: PropTypes.string,
  extraName: PropTypes.string,
  leftAltContent: PropTypes.string,
  leftContent: PropTypes.string,
  mobileImage: PropTypes.string,
  quantityMaximum: PropTypes.number,
  quantityMinimum: PropTypes.number,
  rightContent: PropTypes.string,
  searchListingImage: PropTypes.string,
  titleAltText: PropTypes.string,
  titleText: PropTypes.string,
  unitCost: PropTypes.number,
};

export const productType = {
  memberBookableDate: PropTypes.string,
  memberBookableMonths: PropTypes.number,
  nonMemberBookableDate: PropTypes.string,
  nonMemberBookableMonths: PropTypes.number,
  overlappingSitesType: PropTypes.number,
  productCode: PropTypes.number,
  productName: PropTypes.string,
};

export const sharedConfigurationType = {
  amendAndCancelExtraRestrictions: PropTypes.shape({}),
  availabilityMaxDate: PropTypes.string,
  basketStates: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  bookingAmendmentRestrictionTypes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  bookingConfirmationImage: PropTypes.string,
  bookingTimelines: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  cancellationReasons: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    waiveCancellationFee: PropTypes.bool,
  })),
  configurableValues: PropTypes.shape({
    minLocationSearchRadiusMilesDecimal: PropTypes.number,
    campingUpsellIsEnabled: PropTypes.bool,
  }),
  defaultCampsiteLogoImage: PropTypes.string,
  defaultCampsiteZoomLevel: PropTypes.number,
  defaultCrossingZoomLevel: PropTypes.number,
  itxPackageRules: PropTypes.shape({
    qtyOvernightStaysRequired: PropTypes.number,
    qtyOverseasSiteNightVouchersRequired: PropTypes.number,
  }),
  joinNowLink: PropTypes.string,
  membershipContent: PropTypes.shape({
    buttonText: PropTypes.string,
    creditCardContent: PropTypes.string,
    creditCardDescription: PropTypes.string,
    creditCardTitle: PropTypes.string,
    creditCardValidity: PropTypes.string,
    desktopImageUrl: PropTypes.string,
    directDebitContent: PropTypes.string,
    directDebitDescription: PropTypes.string,
    directDebitTitle: PropTypes.string,
    directDebitValidity: PropTypes.string,
    footerDescription: PropTypes.string,
    footerTitle: PropTypes.string,
    fromText: PropTypes.string,
    imageAltText: PropTypes.string,
    leftContent: PropTypes.string,
    listingButtonText: PropTypes.string,
    listingImageUrl: PropTypes.string,
    listingText: PropTypes.string,
    listingTitle: PropTypes.string,
    mobileImageUrl: PropTypes.string,
    perYearText: PropTypes.string,
    rightContent: PropTypes.string,
    titleText: PropTypes.string,
  }),
  membershipRenewalLink: PropTypes.string,
  membershipTypes: PropTypes.shape({
    additionalFamilyMemberCost: PropTypes.number,
    creditCard: PropTypes.shape({
      costSingle: PropTypes.number,
      costJoint: PropTypes.number,
    }),
    directDebit: PropTypes.shape({
      costSingle: PropTypes.number,
      costJoint: PropTypes.number,
    }),
  }),
  overlappingSitesTypes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  ports: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    portCodes: PropTypes.arrayOf(PropTypes.string),
    zone: PropTypes.number,
  })),
  preBookingCheckIssueTypes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  products: PropTypes.arrayOf(PropTypes.shape(productType)),
  quoteChangeTypes: PropTypes.arrayOf(PropTypes.shape({})),
  siteNightVoucherInfo: PropTypes.shape({
    desktopImage: PropTypes.string,
    extraName: PropTypes.string,
    leftAltContent: PropTypes.string,
    leftContent: PropTypes.string,
    mobileImage: PropTypes.string,
    quantityMaximum: PropTypes.number,
    quantityMinimum: PropTypes.number,
    rightContent: PropTypes.string,
    searchListingImage: PropTypes.string,
    titleAltText: PropTypes.string,
    titleText: PropTypes.string,
    unitCost: PropTypes.number,
  }),
  suppliers: PropTypes.arrayOf(PropTypes.shape({
    supplierCodes: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    logoURL: PropTypes.string,
  })),
  user: PropTypes.shape({}),
  warnBookingCheckIssueTypes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
};

const sitesConfigType = {
  campsiteTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  sortOrderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  featureTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  features: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      imageUrl: PropTypes.string,
      featureTag: PropTypes.string,
      isFilterable: PropTypes.bool,
    }),
  ),
  campaignCodes: PropTypes.shape({
    codes: PropTypes.arrayOf(
      PropTypes.shape({
        campaignCode: PropTypes.string.isRequired,
        isOverseasTab: PropTypes.bool,
        lat: PropTypes.number,
        lon: PropTypes.number,
        zoomLevel: PropTypes.number,
      }),
    ),
  }),
  campingMiniImage: PropTypes.string,
  onSiteFilterableFeatures: PropTypes.arrayOf(PropTypes.string),
  nearbyFilterableFeatures: PropTypes.arrayOf(PropTypes.string),
  pitchTypesLink: PropTypes.string,
  geoLocationPermissionsLink: PropTypes.string,
  pointsOfInterestFilterableFeatures: PropTypes.arrayOf(PropTypes.string),
  disabledFilterableFeatures: PropTypes.arrayOf(PropTypes.string),
  ukSitesTabDefaultSearchSortOrderTypes: PropTypes.arrayOf(PropTypes.number),
  overseasSitesTabDefaultSearchSortOrderTypes: PropTypes.arrayOf(PropTypes.number),
  escortedToursDurationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  suggestedUkItineraryCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  excludeGeoLocationTypes: PropTypes.arrayOf(PropTypes.string),
  mapConfiguration: PropTypes.shape({
    Regions: PropTypes.string,
    RegionBorderColour: PropTypes.string,
    RegionCentrePinColour: PropTypes.string,
    SearchCentreColour: PropTypes.string,
    MyLocationPinColour: PropTypes.string,
    MyLocationSearchZoom: PropTypes.number,
    LocationUpdateIntervalSeconds: PropTypes.number,
  }),
};

const crossingsConfigType = {
  defaultCrossingPreferredTime: PropTypes.string,
  defaultMaxCrossingAccomQuantity: PropTypes.number,
  defaultMaxVehicleOverallHeightCrossing: PropTypes.number,
  defaultMaxVehicleOverallLengthCrossing: PropTypes.number,
  portZoneTypes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      arrivalPort: PropTypes.string,
      arrivalPortLocation: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number,
      }),
      arrivalPortName: PropTypes.string,
      arrivalPortZone: PropTypes.number,
      departurePort: PropTypes.string,
      departurePortLocation: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number,
      }),
      departurePortName: PropTypes.string,
      departurePortZone: PropTypes.number,
      description: PropTypes.string,
      id: PropTypes.string,
      routeCode: PropTypes.string,
      routeCodeWeb: PropTypes.string,
      routeLine: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        }),
      ),
      supplierCode: PropTypes.string,
    }),
  ),
};

export const timeTableType = {
  toDate: PropTypes.string,
  fromDate: PropTypes.string,
};

export const itineraryType = {
  departurePortName: PropTypes.string,
  departurePortZone: PropTypes.number,
  accommodation: PropTypes.arrayOf(PropTypes.shape({
    uId: PropTypes.number,
    name: PropTypes.string,
    maxOccupancy: PropTypes.number,
    quantity: PropTypes.number,
  })),
  isAccomMandatory: PropTypes.bool,
  supplements: PropTypes.arrayOf(PropTypes.shape({})),
  timeTable: PropTypes.shape(timeTableType),
  crossingRouteCode: PropTypes.string,
  crossingDateTime: PropTypes.string,
  routeCode: PropTypes.string,
  routeCodeWeb: PropTypes.string,
  departurePort: PropTypes.string,
  arrivalPort: PropTypes.string,
};

export const momentType = {
  format: PropTypes.func,
  isSame: PropTypes.func,
  isBefore: PropTypes.func,
  isAfter: PropTypes.func,
};

export const datePickerType = {
  months: PropTypes.number,
  minDate: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
  maxDate: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  getCalendar: PropTypes.func,
  onCellClick: PropTypes.func,
  autoApply: PropTypes.bool,
  dateRange: PropTypes.bool,
};

export const queryType = {
  campsiteId: PropTypes.string,
  searchBy: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  types: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
  ]),
  eventType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  features: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};

export const pitchType = {
  code: PropTypes.string,
  daysOnRequest: PropTypes.arrayOf(PropTypes.any),
  description: PropTypes.string,
  hasLowAvailability: PropTypes.arrayOf(PropTypes.bool),
  id: PropTypes.string,
  maxNights: PropTypes.number,
  maxOccupancy: PropTypes.number,
  maxOutfitLength: PropTypes.number,
  memberAvailability: PropTypes.arrayOf(PropTypes.number),
  memberMinPricePerNight: PropTypes.number,
  minChildAge: PropTypes.number,
  minNights: PropTypes.number,
  minPricePerNight: PropTypes.number,
  name: PropTypes.string,
  nightlyPrices: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    price: PropTypes.number,
  })),
  nonMemberAvailability: PropTypes.arrayOf(PropTypes.number),
  nonMemberMinPricePerNight: PropTypes.number,
  partyMemberTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  prioritySortOrder: PropTypes.number,
  selected: PropTypes.bool,
  specialRequests: PropTypes.arrayOf(PropTypes.shape({
    commentId: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  totalCost: PropTypes.number,
  eventType: PropTypes.number,
};

export const quoteType = {
  balanceDueDate: PropTypes.string,
  basketHoldExpiryTimestamp: PropTypes.string,
  basketHoldMaxTimestamp: PropTypes.string,
  basketHoldStartTimestamp: PropTypes.string,
  basketState: PropTypes.number,
  bookingAmendmentBalanceDue: PropTypes.number,
  bookingAmendmentMaxBalanceDue: PropTypes.number,
  campsiteBookings: PropTypes.arrayOf(PropTypes.shape({})),
  crossingBookings: PropTypes.arrayOf(PropTypes.shape({})),
  deposit: PropTypes.number,
  defaultAdultDobDate: PropTypes.string,
  errorCode: PropTypes.number,
  extras: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string,
  isBookingAmendmentQuote: PropTypes.bool,
  isMemberQuote: PropTypes.bool,
  membershipType: PropTypes.number,
  nonMemberSupplementsCost: PropTypes.number,
  outfit: PropTypes.shape(outfitType),
  partyMembers: PropTypes.arrayOf(PropTypes.shape(partyMemberType)),
  product: PropTypes.string,
  quoteId: PropTypes.string,
  totalPrice: PropTypes.number,
  totalPriceIncludesNonMemberSupplements: PropTypes.bool,
};

export const routerType = {
  asPath: PropTypes.string,
  back: PropTypes.func,
  basePath: PropTypes.string,
  beforePopState: PropTypes.func,
  components: PropTypes.shape({}),
  events: PropTypes.shape({}),
  isFallback: PropTypes.bool,
  pathname: PropTypes.string,
  prefetch: PropTypes.func,
  push: PropTypes.func,
  query: PropTypes.shape(queryType),
  reload: PropTypes.func,
  replace: PropTypes.func,
  route: PropTypes.string,
};

export const termsAndConditionsType = {
  errorCode: PropTypes.number,
  termsAndConditionsPostFix: PropTypes.string,
  termsAndConditionsPrefix: PropTypes.string,
  termsAndConditionsSections: PropTypes.arrayOf(PropTypes.string),
};

export const featureType = {
  iconUrl: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.number),
};

export const fileType = {
  fileExtension: PropTypes.string,
  filesizeKb: PropTypes.number,
  name: PropTypes.string,
  url: PropTypes.string,
};

export const eventType = {
  cheapestMemberPrice: PropTypes.number,
  cheapestStandardPrice: PropTypes.number,
  earliestArrival: PropTypes.string,
  eventType: PropTypes.number,
  features: PropTypes.arrayOf(PropTypes.shape(featureType)),
  files: PropTypes.arrayOf(PropTypes.shape(fileType)),
  hasLowAvailability: PropTypes.bool,
  id: PropTypes.string,
  isMemberExclusive: PropTypes.boolean,
  latestArrival: PropTypes.string,
  maxOutfitLength: PropTypes.number,
  offers: PropTypes.arrayOf(PropTypes.any),
  openDates: PropTypes.arrayOf(
    PropTypes.shape({
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),
  ),
};

export const campsiteType = {
  id: PropTypes.string,
  address: PropTypes.shape(addressType),
  contactName: PropTypes.string,
  emailAddress: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape(eventType)),
  features: PropTypes.arrayOf(PropTypes.shape(featureType)),
  hasLowAvailability: PropTypes.bool,
  isFeatured: PropTypes.bool,
  isMemberOnly: PropTypes.bool,
  isoPlaceCode: PropTypes.string,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  name: PropTypes.string,
  numberOfReviews: PropTypes.number,
  pitches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    isMemberExclusive: PropTypes.bool,
    maxChildAge: PropTypes.number,
    maxOccupancy: PropTypes.number,
    maxOutfitLength: PropTypes.number,
    minChildAge: PropTypes.number,
    name: PropTypes.string,
  })),
  rating: PropTypes.number,
  siteCode: PropTypes.string,
  telephoneNumber: PropTypes.string,
  type: PropTypes.number,
  placeUrl: PropTypes.string,
};

export const campsiteBookingType = {
  partyMemberReference: PropTypes.arrayOf(PropTypes.number),
  campsite: PropTypes.shape(campsiteType),
  arrivalDateTime: PropTypes.string,
  id: PropTypes.string,
  bookingDates: PropTypes.shape({}),
  totalPrice: PropTypes.number,
  supplements: PropTypes.arrayOf(PropTypes.shape({})),
};

export const campsiteListingItemType = {
  address: PropTypes.shape(addressType),
  gallery: PropTypes.shape({
    1: PropTypes.string,
  }),
  id: PropTypes.string,
  isFeatured: PropTypes.bool,
  name: PropTypes.string,
  numberOfReviews: PropTypes.number,
  placeEvent: PropTypes.arrayOf(PropTypes.shape(eventType)),
  rating: PropTypes.number,
  topFeatures: PropTypes.arrayOf(PropTypes.shape(featureType)),
  type: PropTypes.number,
  usp: PropTypes.string,
};

export const compareCampsiteType = {
  id: PropTypes.string,
  address: PropTypes.shape(addressType),
  features: PropTypes.arrayOf(PropTypes.shape(featureType)),
  hasLowAvailability: PropTypes.bool,
  isFeatured: PropTypes.bool,
  isMemberOnly: PropTypes.bool,
  isoPlaceCode: PropTypes.string,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  name: PropTypes.string,
  numberOfReviews: PropTypes.number,
  pitches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    isMemberExclusive: PropTypes.bool,
    maxChildAge: PropTypes.number,
    maxOccupancy: PropTypes.number,
    maxOutfitLength: PropTypes.number,
    minChildAge: PropTypes.number,
    name: PropTypes.string,
  })),
  rating: PropTypes.number,
  siteCode: PropTypes.string,
  type: PropTypes.number,
  selectedEvent: PropTypes.shape(eventType),
  memberPrice: PropTypes.number,
  standarPrice: PropTypes.number,
};

export const pinType = {
  id: PropTypes.string,
  isoPlaceCode: PropTypes.string,
  name: PropTypes.string,
  numberOfReviews: PropTypes.number,
  rating: PropTypes.number,
  type: PropTypes.number,
  gallery: PropTypes.shape({
    [PropTypes.number]: PropTypes.shape({
      alternativeFormats: PropTypes.arrayOf(PropTypes.shape({})),
      url: PropTypes.string,
    }),
  }),
};

export const priceType = {
  events: PropTypes.arrayOf(PropTypes.shape({
    cheapestMemberPrice: PropTypes.number,
    cheapestStandardPrice: PropTypes.number,
    eventType: PropTypes.number,
    hasLowAvailability: PropTypes.bool,
    id: PropTypes.string,
    maxOutfitLength: PropTypes.number,
    offers: PropTypes.arrayOf(PropTypes.shape({})),
  })),
  id: PropTypes.string,
};

export const poiType = {
  id: PropTypes.string,
  lat: PropTypes.number,
  lon: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.number,
  zoomLevel: PropTypes.number,
  image: PropTypes.string,
  promotionalText: PropTypes.string,
  offerTitle: PropTypes.string,
  offerEndDateTime: PropTypes.string,
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  poIUrl: PropTypes.string,
};

export const dictionaryItemType = {
  key: PropTypes.string,
  value: PropTypes.string,
};

export const guestType = {
  personId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tempId: PropTypes.number,
  type: PropTypes.number,
  dateOfBirth: PropTypes.string,
  stayStart: PropTypes.string,
  stayEnd: PropTypes.string,
};

export const quotePayloadType = {
  start: PropTypes.string,
  end: PropTypes.string,
  pitchTypeId: PropTypes.string,
  partyMembers: PropTypes.arrayOf(PropTypes.shape(partyMemberType)),
  outfit: PropTypes.shape(outfitType),
  guests: PropTypes.arrayOf(PropTypes.shape(guestType)),
};

export const payloadsType = {
  camping: PropTypes.arrayOf(PropTypes.shape(quotePayloadType)),
  touring: PropTypes.shape(quotePayloadType),
};

export const membershipMemberType = {
  address: PropTypes.shape(addressType),
  fullName: PropTypes.shape({
    prefix: PropTypes.string,
    firstName: PropTypes.string,
    middle: PropTypes.string,
    surname: PropTypes.string,
  }),
  dateOfBirth: PropTypes.string,
  emailAddress: PropTypes.string,
  contactNumber: PropTypes.shape({
    countryPrefix: PropTypes.string,
    number: PropTypes.string,
  }),
};

export const popupsType = {
  siteNightVoucherPopUp: PropTypes.shape({
    open: PropTypes.bool,
    minumum: PropTypes.number,
  }),
  becomeAMemberPopUp: PropTypes.bool,
  bookingOverlapDialog: PropTypes.bool,
  depositComponentSummaryPopUp: PropTypes.shape({
    open: PropTypes.bool,
    components: PropTypes.arrayOf(PropTypes.shape({
      deposit: PropTypes.number,
      name: PropTypes.string,
    })),
  }),
  voucherDialog: PropTypes.bool,
  loginPopUp: PropTypes.shape({
    open: PropTypes.bool,
    title: PropTypes.string,
    prompt: PropTypes.string,
    redirectTo: PropTypes.string,
    showMembership: PropTypes.bool,
  }),
  cancelSiteBookingPopUp: PropTypes.shape({
    open: PropTypes.bool,
    refund: PropTypes.number,
    title: PropTypes.string,
  }),
  basketPopUp: PropTypes.shape({
    open: PropTypes.bool,
    name: PropTypes.string,
  }),
  upSellPopUp: PropTypes.shape({
    open: PropTypes.bool,
    saving: PropTypes.number,
  }),
  removePackagePopUp: PropTypes.shape({
    open: PropTypes.bool,
    componentId: PropTypes.string,
  }),
  removeVouchersPopUp: PropTypes.shape({
    open: PropTypes.bool,
    componentId: PropTypes.string,
  }),
  richTextPopUp: PropTypes.shape({
    open: PropTypes.bool,
    text: PropTypes.string,
  }),
  membershipExpiredPopUp: PropTypes.shape({
    open: PropTypes.bool,
    isOverseas: PropTypes.bool,
    showProceed: PropTypes.bool,
  }),
  noticePopUp: PropTypes.shape({
    open: PropTypes.bool,
    type: PropTypes.string,
  }),
  formPopUp: PropTypes.shape({
    open: PropTypes.bool,
    type: PropTypes.string,
  }),
};

export const popupActionsType = {
  openPopup: PropTypes.func,
  closePopup: PropTypes.func,
  closeAllPopups: PropTypes.func,
  getPopup: PropTypes.func,
};

export const tourItineraryType = {
  descriptionPoints: PropTypes.arrayOf(PropTypes.string),
  imageUrl: PropTypes.string,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  mapPinText: PropTypes.string,
  itineraryDays: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    titleText: PropTypes.string,
  })),
  titleText: PropTypes.string,
};

export const tourType = {
  id: PropTypes.number,
  departureDates: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  fromPrice: PropTypes.number,
  fromPriceSuffix: PropTypes.string,
  gallery: PropTypes.arrayOf(PropTypes.string),
  itinerary: PropTypes.arrayOf(PropTypes.shape(tourItineraryType)),
  location: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  name: PropTypes.string,
  numberOfNights: PropTypes.number,
  tourHighlights: PropTypes.arrayOf(PropTypes.string),
  whatsIncluded: PropTypes.arrayOf(PropTypes.string),
  whatsIncludedIntroText: PropTypes.string,
};

export const ukItineraryType = {
  backgroundImageUrl: PropTypes.string,
  campsiteIds: PropTypes.arrayOf(PropTypes.string),
  country: PropTypes.string,
  descriptionText: PropTypes.string,
  descriptionTitle: PropTypes.string,
  id: PropTypes.number,
  idealFor: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, icon: PropTypes.string }),
  ),
  itineraryCategory: PropTypes.number,
  itineraryLength: PropTypes.number,
  itineraryLengthTitle: PropTypes.string,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
    zoomLevel: PropTypes.number,
  }),
  name: PropTypes.string,
  whatToExpect: PropTypes.arrayOf(PropTypes.string),
  whatToExpectTitle: PropTypes.string,
};

export const validationIssueType = {
  issueType: PropTypes.number,
  relatedBookingIds: PropTypes.arrayOf(PropTypes.string),
};

export const bookingValidationComponentType = {
  issues: PropTypes.arrayOf(PropTypes.shape(validationIssueType)),
  relatedComponentId: PropTypes.number,
};

export const optimizedImageType = {
  baseUrl: PropTypes.string,
  mobile: PropTypes.string,
  mobileLarge: PropTypes.string,
  tablet: PropTypes.string,
  desktop: PropTypes.string,
};

export const refType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(React.Component) }),
]);

export const coordinateType = {
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export const childrenType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.func,
]);

export const reactNodeType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const partStayGuestType = {
  id: PropTypes.string,
  price: PropTypes.number,
};

export const partStayGuestGroupType = {
  groupId: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  price: PropTypes.number,
  id: PropTypes.string,
  guests: PropTypes.arrayOf(PropTypes.shape(partStayGuestType)),
  unsanitizedGuests: PropTypes.arrayOf(PropTypes.shape({})),
};

const htmlElementPropType = (props, propName, componentName) => {
  const value = props[propName];
  const isElement = isHTMLElement(value);
  if (!isElement) {
    return new TypeError(
      `Invalid HTML Element Prop Value: ${propName} in ${componentName}`,
    );
  }
  return null;
};

export const portalNodeType = PropTypes.shape({
  element: htmlElementPropType,
  elementType: PropTypes.string,
  getInitialPortalProps: PropTypes.func,
  mount: PropTypes.func,
  setPortalProps: PropTypes.func,
  unmount: PropTypes.func,
});

export default {
  apolloData: apolloDataType,
  apolloError: apolloErrorType,
  booking: bookingType,
  bookingValidationComponent: bookingValidationComponentType,
  campsite: campsiteType,
  campsiteBooking: campsiteBookingType,
  campsiteListingItem: campsiteListingItemType,
  checkoutExtra: checkoutExtraType,
  children: childrenType,
  client: clientType,
  compareCampsite: compareCampsiteType,
  configuration: sharedConfigurationType,
  crossingsConfig: crossingsConfigType,
  datePicker: datePickerType,
  dictionaryItem: dictionaryItemType,
  event: eventType,
  guest: guestType,
  itinerary: itineraryType,
  itxPackageRules: itxPackageRulesType,
  membershipMember: membershipMemberType,
  moment: momentType,
  networkError: networkErrorType,
  optimizedImage: optimizedImageType,
  outfit: outfitType,
  partyMember: partyMemberType,
  payloads: payloadsType,
  pin: pinType,
  pitch: pitchType,
  poi: poiType,
  popupActions: popupActionsType,
  popups: popupsType,
  portalNode: portalNodeType,
  price: priceType,
  product: productType,
  query: queryType,
  quote: quoteType,
  quotePayload: quotePayloadType,
  ref: refType,
  router: routerType,
  siteNightVoucherInfo: siteNightVoucherInfoType,
  sitesConfig: sitesConfigType,
  termsAndConditions: termsAndConditionsType,
  timeTable: timeTableType,
  tour: tourType,
  tourItinerary: tourItineraryType,
  ukItinerary: ukItineraryType,
  user: userType,
  partyStayGuestsGroup: partStayGuestGroupType,
};
